exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-posts-2019-01-10-introduction-index-md": () => import("./../../../src/posts/2019-01-10-introduction/index.md" /* webpackChunkName: "component---src-posts-2019-01-10-introduction-index-md" */),
  "component---src-posts-2019-02-20-comments-index-md": () => import("./../../../src/posts/2019-02-20-comments/index.md" /* webpackChunkName: "component---src-posts-2019-02-20-comments-index-md" */),
  "component---src-posts-2020-04-23-file-system-index-md": () => import("./../../../src/posts/2020-04-23-file-system/index.md" /* webpackChunkName: "component---src-posts-2020-04-23-file-system-index-md" */),
  "component---src-posts-2020-04-27-dependency-injection-php-index-md": () => import("./../../../src/posts/2020-04-27-dependency-injection-php/index.md" /* webpackChunkName: "component---src-posts-2020-04-27-dependency-injection-php-index-md" */),
  "component---src-posts-2020-05-14-solid-design-index-md": () => import("./../../../src/posts/2020-05-14-solid-design/index.md" /* webpackChunkName: "component---src-posts-2020-05-14-solid-design-index-md" */),
  "component---src-posts-2020-12-25-modern-javascript-index-md": () => import("./../../../src/posts/2020-12-25-modern-javascript/index.md" /* webpackChunkName: "component---src-posts-2020-12-25-modern-javascript-index-md" */),
  "component---src-posts-2021-04-12-php-static-analysis-index-md": () => import("./../../../src/posts/2021-04-12-php-static-analysis/index.md" /* webpackChunkName: "component---src-posts-2021-04-12-php-static-analysis-index-md" */),
  "component---src-posts-2021-06-03-docker-php-xdebug-index-mdx": () => import("./../../../src/posts/2021-06-03-docker-php-xdebug/index.mdx" /* webpackChunkName: "component---src-posts-2021-06-03-docker-php-xdebug-index-mdx" */),
  "component---src-posts-2021-08-05-clean-code-notes-index-md": () => import("./../../../src/posts/2021-08-05-clean-code-notes/index.md" /* webpackChunkName: "component---src-posts-2021-08-05-clean-code-notes-index-md" */),
  "component---src-posts-2021-08-24-zip-php-index-md": () => import("./../../../src/posts/2021-08-24-zip-php/index.md" /* webpackChunkName: "component---src-posts-2021-08-24-zip-php-index-md" */),
  "component---src-posts-2021-09-24-code-smells-index-mdx": () => import("./../../../src/posts/2021-09-24-code-smells/index.mdx" /* webpackChunkName: "component---src-posts-2021-09-24-code-smells-index-mdx" */),
  "component---src-posts-2022-04-03-git-reflog-index-md": () => import("./../../../src/posts/2022-04-03-git-reflog/index.md" /* webpackChunkName: "component---src-posts-2022-04-03-git-reflog-index-md" */),
  "component---src-posts-2024-01-04-linux-101-notes-index-md": () => import("./../../../src/posts/2024-01-04-linux-101-notes/index.md" /* webpackChunkName: "component---src-posts-2024-01-04-linux-101-notes-index-md" */),
  "component---src-posts-2024-01-28-the-strategy-pattern-index-md": () => import("./../../../src/posts/2024-01-28-the-strategy-pattern/index.md" /* webpackChunkName: "component---src-posts-2024-01-28-the-strategy-pattern-index-md" */),
  "component---src-posts-2024-03-17-symfony-entity-timestamp-metadata-index-md": () => import("./../../../src/posts/2024-03-17-symfony-entity-timestamp-metadata/index.md" /* webpackChunkName: "component---src-posts-2024-03-17-symfony-entity-timestamp-metadata-index-md" */),
  "component---src-posts-2024-04-27-re-introduction-to-git-index-md": () => import("./../../../src/posts/2024-04-27-re-introduction-to-git/index.md" /* webpackChunkName: "component---src-posts-2024-04-27-re-introduction-to-git-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2019-01-10-introduction-index-md": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2019-01-10-introduction/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2019-01-10-introduction-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2020-04-27-dependency-injection-php-index-md": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2020-04-27-dependency-injection-php/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2020-04-27-dependency-injection-php-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2020-12-25-modern-javascript-index-md": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2020-12-25-modern-javascript/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2020-12-25-modern-javascript-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2021-06-03-docker-php-xdebug-index-mdx": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2021-06-03-docker-php-xdebug/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2021-06-03-docker-php-xdebug-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2022-04-03-git-reflog-index-md": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2022-04-03-git-reflog/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2022-04-03-git-reflog-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2024-01-04-linux-101-notes-index-md": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2024-01-04-linux-101-notes/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2024-01-04-linux-101-notes-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2024-01-28-the-strategy-pattern-index-md": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2024-01-28-the-strategy-pattern/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2024-01-28-the-strategy-pattern-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2024-03-17-symfony-entity-timestamp-metadata-index-md": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2024-03-17-symfony-entity-timestamp-metadata/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2024-03-17-symfony-entity-timestamp-metadata-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2024-04-27-re-introduction-to-git-index-md": () => import("./../../../src/templates/BlogPostTemplate.tsx?__contentFilePath=/codebuild/output/src1129802915/src/achilleskal.com/src/posts/2024-04-27-re-introduction-to-git/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-posts-2024-04-27-re-introduction-to-git-index-md" */)
}

